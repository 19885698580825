
/* Header container */
/*.app-header {}*/

/* Remove divider background color */
.app-header.ui.menu .item::before,
.app-header.ui.menu .header.item::before {
    background: none;
}

/* Remove margins from menu icons and add color */
.app-header.ui.menu i.icon.app-header {
    margin: 0;
    color: #2e2e2e;
}

/* Add backgound shadows and backgound color*/
.app-header.ui.menu {
    box-shadow: 3px 3px 13px #959595;
    background-color: #FFF;
    /*background-image: linear-gradient(to right, #ffffff, #f0f0f0, #ffffff);*/
    background-image: linear-gradient(to bottom right, #fff 25%, #ebf2f9 50%, #ebf2f9 75%, #d8e4f3 100%);
}

/* sm: 0, md: 768, lg: 1024, xl: 1192*/
/* Visibility classes */
@media only screen and (max-width: 1024px) {
    .visible-lg {
        display: none !important;
    }
}

/* Container */
.file-dropzone {
    margin: 10px 0 20px 0 ;
}

/* Dropzone is in focused state */
.container .dropzone.focus {
    border-color: orange;
}

/* Dropzone is in active state */
.container .dropzone.active {
    border-color: blue;
}

/* Dropzone is in reject state */
.container .dropzone.reject {
    border-color: red;
}

/* Dropzone is in accept state */
.container .dropzone.accept {
    border-color: green;
}

.container .dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    min-height: 18rem;
    justify-content: center;
};

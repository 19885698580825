.survey-link {
    font-size: 1.1rem;
}

.survey-deadline {
    display: inline;
    margin-left: 5px;
    font-style: italic;
    color: #999999;
}

.app .accordion.ui .title:not(.ui) {
    font-weight: bold;
    font-size: 1rem;
    padding-left: 10px;
}

.app .accordion.ui .title.active:not(.ui) {
    border-bottom: 1px solid #CCCCCC;
}

.app .accordion.ui {
    background-color: rgba(100, 100, 100, 0.05);
    margin-bottom: 20px;
}
.app .ui.accordion:not(.styled) .accordion .title~.content:not(.ui), .app .ui.accordion:not(.styled) .title~.content:not(.ui) {
    padding: 10px 30px;
}

/* inventory survey */

.ui.table.inventory td.header {
    font-weight: bold;
}

.ui.table.inventory table {
    border: none;
}

.ui.table.inventory table tr.header td {
    border-top: none;
    border-bottom: 1px solid #999999;
}

/* label */

.app .ui.form div.fieldlabel {
    display: block;
    margin: 0 0 .28571429rem 0;
    color: rgba(0, 0, 0, .87);
    font-size: .92857143em;
    font-weight: 700;
    text-transform: none;
}

.app .ui.form .required.fieldlabel:after {
    margin: -.2em 0 0 .2em;
    content: '*';
    color: #db2828;
}

/* clearing survey */

.ui.table.clearing td.header {
    font-weight: bold;
    vertical-align: top;
}

.ui.table.clearing td.header div {
    margin-top: 10px;
}

.ui.table.clearing table tr.header td {
    border-top: none;
    border-bottom: 1px solid #999999;
}

.ui.table.clearing tbody+tbody tr:first-child td {
    border-top: none;
}

.app .ui.form .clearing .disabled.field, .app .ui.form .clearing .disabled.fields .field, .app .ui.form .clearing .field :disabled {
    opacity: 1;
    color: black;
    border: none;
    padding-left: 0;
    padding-right: 0;
    text-align: right;
}

.app .clearing .ui.disabled.input, .app .clearing .ui.input:not(.disabled) input[disabled] {
    opacity: 1;
}

.app .ui.segment.clearing {
    margin-top: 0px;
    margin-bottom: 0px;
}

.app div.clearing h2.ui.header {
    margin-top: 10px;
    margin-bottom: 5px;
}

.app div.clearing .accordion {
    padding-bottom: 10px;
}

.app .ui.grid table.clearing tr th {
    padding-top: 10px;
    padding-bottom: 10px;
}
.app .ui.grid table.clearing tr td {
    border-top: none;
    white-space: nowrap;
    clear: both;
}
.app .ui.grid table.clearing tr td:nth-child(2), .app table.clearing tr td:nth-child(4) {
    text-align: right;
}

@media only screen and (max-width: 767px) {
    .app .ui.grid table.clearing tr th {
        text-align: right;
    }
    .app .ui.grid table.clearing tr td {
        text-align: right;
    }
    .app .ui.grid table.clearing .field.with-unit.w50 .unit-container {
        width: 20px;
    }
    .app .ui.grid table.clearing .field.with-unit.w50 .field-container {
        width: calc(100% - 30px);
    }
    .app .ui.grid table.clearing tr td>.fieldlabel {
        text-align: left;
    }
}

/* Add extra padding to footer */
.footer .ui.segment {
    padding: 2em;
}

/* Fixed footer is always at the bottom! */
.footer.fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;    
}

/* Remove extra margin on mobile */
@media only screen and (max-width: 767px) {
    .footer.ui.container {
        margin: 0px !important;
    }
}
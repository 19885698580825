.portal-announcement-viewer {
    position: relative;
    top: 90px;
    margin: 10px 10px -15px;
    padding-bottom: 0;
    z-index: 100;
}

.portal-announcement-viewer .ui.message {
    margin-top: 0px;
    margin-bottom: 0px;
    margin: 5px 0 1px 0 !important;
}

.app .ui.info.message, .app .ui.info.message .header {
    color: rgb(156, 187, 225);
    background-color: #FFF;
}

.app .ui.negative.message, .app .ui.negative.message .header {
    color: rgb(185, 52, 110);
    background-color: #FFF;
}

.app .ui.info.message p, .app .ui.negative.message p {
    color: black;
}

/* Loader overlay */
#overlay {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.85);
    z-index: 2;
    cursor: pointer;
    overflow-x: hidden;
}
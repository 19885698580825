.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures that the layout stretches to at least the full viewport height. */
  text-align: left;
}

#root {
  background-color: #f0f0f0;
  /*background-image: linear-gradient(to bottom, #f0f0f0 0%, #eceaea 50%, #d5d1d1 75%, #B3C4D9 100%);*/
}

.app .ui.dimmer {
  background-color: rgba(255,255,255,0.75) !important;
}

.app .pushable>.pusher:after {
  background-color: rgba(255,255,255,0.75);
}

/* General page */
.page {
    margin: 10px;
    background-color: #fff;
    padding: 40px 40px 60px 40px;
    border-radius: 5px;
}

/* System pages */
.systempage {
    text-align: center;
}
.systempage h1 {
    font-size: 100px;
    font-weight: bold;
    margin-top: 100px;
    margin-bottom: 0;
}
.systempage p {
    font-size: 50px;
    font-weight: bold;
}

/* field headers */
.app .ui.sub.header {
    text-transform: none;
}

.app h2.ui.header {
    color: #1F529F;
    font-size: 1rem;
    clear: both;
}
#loader > div img {
    width: 60px;
    margin: auto;
    display: inline;
}

.loader-content {
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 20px;
}

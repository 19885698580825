
/* Paged table */
.table-wrapper td::first-letter,
.table-wrapper .button::first-letter {
    text-transform: uppercase;
}

.ui.table thead th.sortable-table {
    cursor: pointer;
}

.pagination-container {
    width: 100%;
    margin: 1rem 0 0;
    text-align: center;
}

.pagination-container .top {
    margin: 0 0 1rem 0;
    padding-top: 1rem;
    border-top: 1px solid #eee;
}

.pagination-container a {
    font-size: 14px;
}

.table-wrapper {
    overflow: auto;
    margin: 10px 0 20px 0;
}

.table-wrapper .collapsable td:first-child {
    cursor: pointer;
    vertical-align: top;
    font-size: 16px;
    width: 38px;
    color: #2DA96B;
}

.table-wrapper .collapsed td {
    white-space: nowrap;
    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table-wrapper .open .header {
    font-weight: bold;
}

.table-wrapper .open div {
    margin-bottom: 0.5rem;
}    

.table-wrapper .open div :last-child {
    margin-bottom: 0;
}

#root .table-wrapper .management .ui.button {
    margin: 1px 5px 1px 0px;
}

.table-wrapper .ui.table thead th {
    border-bottom: 1px solid rgb(151, 192, 0);
}

.table-wrapper .ui.table th {
    border-bottom: 1px solid rgb(151, 192, 0);
    background: #f9fafb;
    padding: 0.92857143em 0.78571429em;
    font-weight: 700;
}


.authentication-error {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.error-details {
    border: 1px solid #999;
    text-align: left;
    padding: 20px;
}
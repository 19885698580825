.extended-button.ui.icon {
    min-width: 138px;
}

.ui.button.extended-button {
    border: 1px solid rgb(156, 187, 225);
    background-color: #FFF  !important;
    color: #1F529F !important;
}

.ui.button.extended-button.cancel-button {
    border: 1px solid lightgray;
}
.ui.button.extended-button.delete-button {
    border: 1px solid rgb(185, 52, 110);
    color: rgb(185, 52, 110);
}
/* Push content section below fixed header */
.app-content {
    padding-top: 100px;
    /*height: 100%;*/
    min-height: 85hv;

    /* CASE Fixed footer 
        - set overflow 
        - add padding
        Else comment out!
    */

    /* overflow-y: scroll; */
    /*padding-bottom: 169px; */

}

/* Remove pusher border 
    add styles and height */
.app-content .ui.segment.pushable.patch {
    height: 100%;
    border: 0;
    box-shadow: none;
    border-radius: 0;
    /*background-color: lightgrey;*/
    background: none;
}


/* grouping form fields */
.app .ui.segment.form-segment {
    margin-bottom: 1.8rem;
    margin-top: 1.8rem;
}

.ui.segment.form-segment .ui.label {
    margin-top: -27px;
    margin-bottom: 15px;
    margin-left: -5px;
    padding: 5px;
    display: block;
    width: auto;
    float: left;
    background-color: #FFF;
    font-size: 1.2rem;
}

.ui.segment.form-segment .ui.grid {
    margin-bottom: 0;
    clear: both;
}

.app .ui.segment.form-segment .ui.label {
    margin-top: 1rem;
}

/* segments */
.app .ui.segment.invisible {
    border: none;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
}

.wrap-newline {
    white-space: pre-wrap;
}

.ui.form .field.with-unit.w50 .field-container {
    width: calc(100% - 60px);
    margin-right: 10px;
    float: left;
}

.ui.form .field.with-unit.w50 .unit-container {
    width: 50px;
    float: left;
    margin-top: 0.5rem;
}

.ui.form .field.with-unit.w100 .field-container {
    width: calc(100% - 110px);
    margin-right: 10px;
    float: left;
}

.ui.form .field.with-unit.w100 .unit-container {
    width: 100px;
    float: left;
    margin-top: 0.5rem;
}

.app i.blue.icon {
    color: #1F529F!important;
}

.app i.blue.icon.bordered.inverted, .app i.blue.icon.circular.inverted {
    background-color: #1F529F!important;
}

.app a {
    color: #1F529F;
  }


  /* error in input */
  .ui.input.error input[type=number] {
    background-color: #fff6f6;
    border-color: #e0b4b4;
    color: #9f3a38;
    box-shadow: none;
}

/* hide asterisk from empty label */
.ui.form .hide-asterisk .required.field>.checkbox:after, 
.ui.form .hide-asterisk .required.field>label:after, 
.ui.form .hide-asterisk .required.fields.grouped>label:after, 
.ui.form .hide-asterisk .required.fields:not(.grouped)>.field>.checkbox:after, 
.ui.form .hide-asterisk .required.fields:not(.grouped)>.field>label:after {
    color: #FFF;
}

/* remove extra spacing from grid */
.app .ui.grid>.row.tight {
    padding: 0;
}
.app .ui.grid>.row.tight:first-child {
    padding-top: 1rem;
}
.app .ui.grid>.row.tight:last-child {
    padding-bottom: 1rem;
}
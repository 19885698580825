/* Logo styles */
.hamburger .logo {
    border-bottom: 1px solid rgba(34,36,38,.15);
    margin-bottom: 3px;
}

/* Logo must be inline in order to show the company name on the side */
.hamburger .logo img.ui {
    display: inline;
    margin-right: 10px;
}

/* Make divider margin smaller */
.hamburger .ui.divider {
    margin: 3px;
}

/* Custom bottom container for the profile */
.hamburger .custom.bottom.menu.item.profile {
    position: fixed;
    left: 0;
    bottom: 130px;
    top: auto;
    right: auto;
    width: 100%;
    border-top: 1px solid rgba(34,36,38,.15);
    background-color: #fff;
}

/* Custom bottom container for the provacy statement */
.hamburger .custom.bottom.menu.item.privacy {
    position: fixed;
    left: 0;
    bottom: 90px;
    top: auto;
    right: auto;
    width: 100%;
    border-top: 1px solid rgba(34,36,38,.15);
    background-color: #fff;
}

/* Custom bottom container for the logout */
.hamburger .custom.bottom.menu.item.logout {
    position: fixed;
    left: 0;
    bottom: 50px;
    top: auto;
    right: auto;
    width: 100%;
    border-top: 1px solid rgba(34,36,38,.15);
    background-color: #fff;
}

/* Fixed bottom menu for the app name and version */
.hamburger .ui.fixed.bottom.menu {
    padding-top: 12px;
    height: 50px;
}

/* Messages component */
/* Use if you want the message to be 100% width of the screen*/
.message-container-full-width {
    position: fixed;
    z-index: 100;
    width: calc(100% - 40px);
    padding: 40px 0px 10px 0px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

/* Use if you want to have the message inside page <Content><Container>HERE</Container></Content>*/
.message-container-page {
    position: fixed;
    z-index: 100;
    width: inherit;
    padding: 40px 0px 10px 0px;
}


.messages {
    margin: 0;
    padding: 0;
}

.messages.active {
    background-color: rgba(1, 1, 1, 0.01);
    padding: 0px 25px;
    margin: 0;
}

.messages.active .ui.message {
    margin: 5px 0;
}
